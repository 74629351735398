<template>
  <div>
    <slide-x-right-transition>
      <div v-if="!round.roundCompleted && !missedRoundCompleted">
        <div id="MatchRenderer" class="vx-row" v-if="setData" @contextmenu.prevent>
          <div class="MatchAppScreen w-full flex items-center justify-center overflow-hidden">
            <div class="MatchAppParent flex-col">
              <div class="MatchHeader">
                <div class="flex items-center">
                  <img
                    :src="setData.set?.thumbnail"
                    v-if="setData.set?.thumbnail"
                    width="40"
                    height="40"
                    class="rounded shadow-md cursor-pointer-match block mr-3"
                    draggable="false"
                    alt=""
                  />
                  <span class="flex items-center gap-x-5">
                    <h2 class="font-bold ml-2">{{ setData.set?.name }}</h2>
                    <h2>|</h2>
                    <h2 v-if="currentMatchType === 'normal'" class="font-light">Round {{ currentMatchRound }}</h2>
                    <h2 v-else class="font-light">
                      Missed Term Practice <i>({{ missedRoundData.unsplitTerms.length }} Terms)</i>
                    </h2>
                  </span>
                </div>

                <div class="flex items-center gap-x-6">
                  <div v-if="currentMatchType === 'normal'" class="flex items-center gap-x-1">
                    <lottie-animation
                      path="https://connect-cdn.intellectualpoint.com/assets/lotties/lf30_editor_b9vegits.json"
                      style="width: 2rem !important; height: 3rem !important"
                      id="stopwatch"
                      :loop="true"
                    />
                    <h4>{{ longFormattedTime }}</h4>
                  </div>

                  <div v-else class="flex items-center gap-x-1">
                    <h4>{{ longFormattedTime }}</h4>
                    <lottie-animation
                      path="https://connect-cdn.intellectualpoint.com/assets/lotties/lf30_editor_b9vegits.json"
                      style="width: 2rem !important; height: 3rem !important"
                      id="stopwatch"
                      :loop="true"
                    />
                  </div>

                  <radial-progress-bar
                    :diameter="70"
                    :completed-steps="round.currentRoundScore"
                    :total-steps="300"
                    :animateSpeed="1100"
                    timingFunc="ease"
                    innerStrokeColor="#181d2a"
                    startColor="#31B952"
                    stopColor="#0cdc62"
                    :strokeWidth="6"
                    :innerStrokeWidth="6"
                    v-if="currentMatchType === 'normal'"
                  >
                    <h4 class="font-bold">{{ round.currentRoundScore }}</h4>
                  </radial-progress-bar>
                </div>
              </div>
              <div class="MatchAppContainer" v-if="currentMatchType === 'normal'">
                <div
                  class="MatchItem cursor-pointer-match"
                  :class="{
                    correct: item.status === 'correct',
                    incorrect: item.status === 'incorrect',
                  }"
                  :style="{
                    backgroundColor:
                      (item.type === 'term' && item.id === selectedTerm.id) ||
                      (item.type === 'definition' && item.id === selectedDefinition.id)
                        ? `rgba(${selectedColor}, 0.2)`
                        : '#02050d',
                    borderColor:
                      (item.type === 'term' && item.id === selectedTerm.id) ||
                      (item.type === 'definition' && item.id === selectedDefinition.id)
                        ? `rgba(${selectedColor}, 1)`
                        : '#181d2a',
                  }"
                  @click="selectItem(item)"
                  v-for="item in matchRoundsData[currentMatchRound - 1]?.terms"
                  v-if="item.status !== 'removed'"
                >
                  <h2 class="MatchText">
                    {{ item.item }}
                  </h2>
                </div>
                <div class="BlankItem" v-else>&nbsp;</div>
              </div>
              <div class="MatchAppContainer" v-else>
                <div
                  class="MatchItem cursor-pointer-match"
                  :class="{
                    correct: item.status === 'correct',
                    incorrect: item.status === 'incorrect',
                  }"
                  :style="{
                    backgroundColor:
                      (item.type === 'term' && item.id === selectedTerm.id) ||
                      (item.type === 'definition' && item.id === selectedDefinition.id)
                        ? `rgba(${selectedColor}, 0.2)`
                        : '#02050d',
                    borderColor:
                      (item.type === 'term' && item.id === selectedTerm.id) ||
                      (item.type === 'definition' && item.id === selectedDefinition.id)
                        ? `rgba(${selectedColor}, 1)`
                        : '#181d2a',
                  }"
                  @click="selectItem(item)"
                  v-for="item in missedRoundData.terms"
                  v-if="item.status !== 'removed'"
                >
                  <h2 class="MatchText" v-if="item.type === 'term'">
                    {{ item.item }}
                  </h2>
                  <h2 class="MatchText" v-else>
                    {{ item.item }}
                  </h2>
                </div>
                <div class="BlankItem" v-else>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="simple-spinner">
          <span></span>
        </div>
      </div>
    </slide-x-right-transition>

    <slide-x-left-transition>
      <div v-if="round.roundCompleted" class="RoundReportWrapper w-full flex-col">
        <div class="flex justify-between mb-6" style="width: 75%">
          <div class="flex flex-col justify-center items-start">
            <h1 v-if="currentMatchRound !== availableRounds" class="text-6xl">Round #{{ currentMatchRound }} Complete!</h1>
            <h1 v-else class="text-6xl">Set Complete!</h1>

            <h4 v-if="currentMatchRound !== availableRounds" class="font-light">You matched all six terms correctly!</h4>
            <h4 v-else-if="currentMatchRound === availableRounds && availableRounds === 1" class="font-light">
              You completed the round with a total score of <strong>{{ currentScore }}</strong> out of
              <strong>{{ totalPossibleScore }}</strong
              >!
            </h4>
            <h4 v-else class="font-light">
              You completed all <strong>{{ availableRounds }}</strong> rounds with a total score of <strong>{{ currentScore }}</strong> out
              of <strong>{{ totalPossibleScore }}</strong
              >!
            </h4>
          </div>

          <div class="flex justify-end gap-x-4 items-center">
            <!-- <span @click="leaderboardPopupActive = true" v-if="currentMatchRound === availableRounds">
              <vx-tooltip text="View Class Leaderboard" position="top" class="text-xl cursor-pointer" color="#181d2a">
                <radial-progress-bar
                  :diameter="110"
                  :completed-steps="currentScore"
                  :total-steps="totalPossibleScore"
                  :animateSpeed="1100"
                  timingFunc="ease"
                  innerStrokeColor="#181d2a"
                  startColor="#FF9F43"
                  stopColor="#FF9F43"
                  :strokeWidth="8"
                  :innerStrokeWidth="8"
                  class="relative"
                >
                  <font-awesome-icon icon="fa-regular fa-trophy" class="absolute h-12 w-12" />
                </radial-progress-bar>
              </vx-tooltip>
            </span> -->
          </div>
        </div>

        <div class="flex relative h-4 rounded-lg my-4" style="width: 75%">
          <div
            class="flex items-center justify-center absolute w-0 h-full rounded-lg z-10"
            :style="{ width: progressPercent + '%', transition: 'all 1s ease-in-out 3s' }"
          >
            <span class="absolute w-full h-full rounded-lg z-10 bg-success"></span>
            <vx-tooltip
              :text="'Total Score Out of ' + totalPossibleScore"
              position="bottom"
              class="absolute right-0 text-xl"
              style="top: 0.25rem"
              color="#181d2a"
              >``
              <span
                class="absolute flex gap-x-1 items-center justify-center z-20 px-4 py-2"
                style="right: -2.6rem; bottom: 0.5rem; background: #192538; border-radius: 0.8rem"
              >
                <h5 class="select-none text-white font-bold">{{ currentScore }}</h5>
                <font-awesome-icon icon="fa-regular fa-sparkles" class="h-4 w-4 z-20" />
              </span>
            </vx-tooltip>
          </div>

          <span class="absolute w-full h-full rounded-lg" style="background: #181d2a"></span>
        </div>

        <div class="RoundReportContainer">
          <div class="w-1/2 flex flex-col justify-between" style="padding: 1.3rem 0rem 1.6rem 0rem">
            <div>
              <div class="flex flex-col items-start">
                <div class="flex items-center gap-x-2">
                  <lottie-animation
                    path="https://connect-cdn.intellectualpoint.com/assets/lotties/sparkles.json"
                    style="width: 3rem !important; height: 4rem !important"
                    :loop="true"
                  />
                  <span v-if="currentMatchRound !== availableRounds" class="flex items-center gap-x-2">
                    <h3>Round Score:</h3>
                    <h3 class="font-light">{{ matchRoundsData[currentMatchRound - 1]?.score }}/300</h3>
                  </span>
                  <span v-else class="flex items-center gap-x-2">
                    <h3>Total Score:</h3>
                    <h3 class="font-light">{{ currentScore }}/{{ totalPossibleScore }}</h3>
                  </span>
                </div>

                <div class="flex items-center gap-x-2">
                  <lottie-animation
                    path="https://connect-cdn.intellectualpoint.com/assets/lotties/lf30_editor_b9vegits.json"
                    style="width: 3rem !important; height: 4rem !important"
                    id="stopwatch"
                    :loop="true"
                  />
                  <span v-if="currentMatchRound !== availableRounds" class="flex items-center gap-x-2">
                    <h3>Time Taken:</h3>
                    <h3 class="font-light">{{ matchRoundsData[currentMatchRound - 1]?.time }}</h3>
                  </span>
                  <span v-else class="flex items-center gap-x-2">
                    <h3>Total Time Taken:</h3>
                    <h3 class="font-light">{{ totalSetTime }}</h3>
                  </span>
                </div>

                <div class="flex items-center gap-x-2">
                  <lottie-animation
                    path="https://connect-cdn.intellectualpoint.com/assets/lotties/lf30_editor_nesztu9e.json"
                    style="width: 3rem !important; height: 4rem !important"
                    :loop="true"
                  />
                  <span v-if="currentMatchRound !== availableRounds" class="flex items-center gap-x-2">
                    <h3>Terms Remaining:</h3>
                    <h3 class="font-light">{{ termCount - 6 * currentMatchRound }}</h3>
                  </span>
                  <span v-else class="flex items-center gap-x-2">
                    <h3>Terms Completed:</h3>
                    <h3 class="font-light">{{ termCount }}</h3>
                  </span>
                </div>

                <div v-if="currentMatchRound >= 2" class="flex items-center gap-x-2 mt-1">
                  <lottie-animation
                    path="https://connect-cdn.intellectualpoint.com/assets/lotties/average-time-lottie.json"
                    style="width: 3rem !important; height: 4rem !important"
                    class="text-white"
                    :loop="true"
                  />
                  <span class="flex items-center gap-x-2">
                    <h3>Average Round Time:</h3>
                    <h3 class="font-light">{{ averageTime }}</h3>
                  </span>
                </div>
              </div>
            </div>

            <div class="flex w-full items-center gap-x-4 mt-16">
              <vs-button
                class="w-full font-bold cursor-pointer-match"
                size="large"
                icon="icon-arrow-left"
                icon-pack="feather"
                color="danger"
                v-if="currentMatchRound !== availableRounds"
                @click="$router.push('/study/select')"
                >Exit to Selector
              </vs-button>

              <vs-button
                class="w-full font-bold cursor-pointer-match"
                size="large"
                icon="icon-arrow-right"
                icon-after
                icon-pack="feather"
                color="success"
                v-if="currentMatchRound !== availableRounds"
                @click="nextRound"
                >Next Round
              </vs-button>

              <div v-if="currentMatchRound === availableRounds" class="flex items-center w-full gap-x-4">
                <vs-button
                  class="font-bold cursor-pointer-match"
                  style="width: 40%"
                  size="large"
                  icon="icon-arrow-left"
                  icon-pack="feather"
                  color="danger"
                  v-if="currentMatchRound === availableRounds && missedTerms.length >= 4"
                  @click="$router.push('/study/select')"
                  >Exit to Selector
                </vs-button>

                <vs-button
                  class="font-bold cursor-pointer-match"
                  style="width: 50%"
                  size="large"
                  icon="icon-arrow-left"
                  icon-pack="feather"
                  color="danger"
                  v-if="currentMatchRound === availableRounds && missedTerms.length < 4"
                  @click="$router.push('/dashboard')"
                  >Exit to Dashboard
                </vs-button>

                <vs-button
                  class="font-bold cursor-pointer-match"
                  style="width: 50%"
                  size="large"
                  icon="icon-arrow-right"
                  icon-after
                  icon-pack="feather"
                  color="success"
                  v-if="currentMatchRound === availableRounds && missedTerms.length < 4"
                  @click="$router.push('/study/select')"
                  >Set Selector
                </vs-button>

                <vs-button
                  class="font-bold cursor-pointer-match"
                  style="width: 60%"
                  size="large"
                  icon="icon-star"
                  icon-after
                  icon-pack="feather"
                  color="success"
                  v-if="currentMatchRound === availableRounds && missedTerms.length >= 4"
                  @click="practiceMissedTerms"
                  >Practice Missed Terms
                </vs-button>
              </div>
            </div>
          </div>
          <div class="RoundContainer flex flex-col gap-y-2 w-1/2">
            <div v-for="(round, index) in matchRoundsData" class="flex items-center justify-between bg-dark main-border rounded-lg p-4">
              <div class="flex gap-x-12" style="width: 80%">
                <span class="flex items-center gap-x-3">
                  <vx-tooltip
                    :text="round.score > 0 ? round.score + '/300 Possible Score' : 'Incomplete Round'"
                    position="left"
                    color="#181d2a"
                  >
                    <radial-progress-bar
                      :diameter="60"
                      :completed-steps="round.score > 0 ? round.score : 300"
                      :total-steps="300"
                      :animateSpeed="1100"
                      timingFunc="ease"
                      innerStrokeColor="#181d2a"
                      :startColor="round.score > 0 ? '#31B952' : '#c93738'"
                      :stopColor="round.score > 0 ? '#31B952' : '#c93738'"
                      :strokeWidth="6"
                      :innerStrokeWidth="6"
                    >
                      <h5 v-if="round.score > 0" class="font-light select-none">{{ round.score }}</h5>
                      <h5 v-else class="font-light select-none">N/A</h5>
                    </radial-progress-bar>
                  </vx-tooltip>

                  <div class="flex flex-col">
                    <h4 class="select-none">Round {{ index + 1 }}</h4>
                    <h4 v-if="round.score > 0" class="flex flex-start font-light select-none">Completed in {{ round.time }}</h4>
                    <h4 v-else class="flex flex-start font-light select-none">Incomplete</h4>
                  </div>
                </span>
              </div>
              <div class="flex justify-end" style="width: 20%">
                <span v-if="round.score > 0" class="ViewTermsButton cursor-pointer-match" @click="viewRoundTerms(index + 1)"
                  >View Terms</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="missedRoundCompleted" class="RoundReportWrapper w-full flex-col">
        <div class="RoundReportContainer">
          <div class="w-1/2 flex flex-col justify-between" style="padding: 1.3rem 0rem 1.6rem 0rem">
            <div>
              <div class="flex flex-col justify-center items-start">
                <h1 class="text-6xl">Great Job, {{ activeUserInfo.firstName }}!</h1>

                <h4>You matched all {{ missedRoundData.unsplitTerms.length }} terms correctly this time!</h4>
              </div>
              <div class="flex flex-col items-start">
                <div class="flex items-center gap-x-2 mt-6">
                  <lottie-animation
                    path="https://connect-cdn.intellectualpoint.com/assets/lotties/lf30_editor_b9vegits.json"
                    style="width: 3rem !important; height: 4rem !important"
                    id="stopwatch"
                    :loop="true"
                  />
                  <span class="flex items-center gap-x-2">
                    <h3>Time Taken:</h3>
                    <h3 class="font-light">{{ missedRoundData.time }}</h3>
                  </span>
                </div>

                <div class="flex items-center gap-x-2 mt-2">
                  <lottie-animation
                    path="https://connect-cdn.intellectualpoint.com/assets/lotties/lf30_editor_nesztu9e.json"
                    style="width: 3rem !important; height: 4rem !important"
                    :loop="true"
                  />
                  <span class="flex items-center gap-x-2">
                    <h3>Missed Terms Practiced:</h3>
                    <h3 class="font-light">{{ missedRoundData.unsplitTerms.length }}</h3>
                  </span>
                </div>
              </div>
            </div>

            <div class="flex w-full items-center gap-x-4 mt-16">
              <vs-button
                class="w-full font-bold cursor-pointer-match"
                size="large"
                icon="icon-arrow-left"
                icon-pack="feather"
                color="danger"
                @click="returnToSummary"
                >Return to Summary
              </vs-button>

              <vs-button
                class="w-full font-bold cursor-pointer-match"
                size="large"
                icon="icon-arrow-right"
                icon-after
                icon-pack="feather"
                color="success"
                @click="practiceMissedTerms"
                >Practice Again
              </vs-button>
            </div>
          </div>
          <div class="RoundContainer flex flex-col gap-y-2 w-1/2">
            <div
              v-for="(term, index) in missedRoundData.unsplitTerms"
              class="flex items-center justify-between bg-dark main-border rounded-lg p-4"
            >
              <div class="flex gap-x-12 w-full">
                <span class="flex items-center gap-x-3">
                  <radial-progress-bar
                    :diameter="60"
                    :completed-steps="index + 1"
                    :total-steps="missedRoundData.unsplitTerms.length"
                    :animateSpeed="1100"
                    timingFunc="ease"
                    innerStrokeColor="#181d2a"
                    startColor="#31B952"
                    stopColor="#0cdc62"
                    :strokeWidth="6"
                    :innerStrokeWidth="6"
                  >
                    <h5 class="font-light select-none">{{ index }}</h5>
                  </radial-progress-bar>

                  <div class="flex flex-col">
                    <h4 class="select-none">{{ term.term }}</h4>
                    <h4 class="flex flex-start font-light select-none">{{ term.definition }}</h4>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </slide-x-left-transition>

    <vs-popup :active.sync="viewTermPopupActive" class="pb-3" :title="`Round ${viewTermPopupRound} Terms`">
      <div class="flex flex-col gap-y-3 p-2 pt-2 FullTermContainer">
        <div
          v-for="round in matchRoundsData[viewTermPopupRound - 1]?.unsplitTerms"
          class="flex flex-col bg-dark main-border rounded-lg p-5"
        >
          <h3>{{ round.term }}</h3>
          <p class="text-dark">{{ round?.definition }}</p>
        </div>
      </div>
    </vs-popup>

    <transition name="PopupOverlay">
      <div v-if="leaderboardPopupActive" @click="leaderboardPopupActive = false" class="PopupOverlay"></div>
    </transition>

    <transition name="PopupOverlay">
      <div v-if="leaderboardPopupActive" class="LeaderboardPopup flex flex-col items-start overflow-hidden">
        <!-- Title header -->
        <div class="flex justify-between main-bottom-border w-full p-6 pb-5 bg-dark">
          <div class="flex items-center">
            <img
              :src="setData.set?.thumbnail"
              v-if="setData.set?.thumbnail"
              width="55"
              height="55"
              class="rounded-lg shadow-md cursor-pointer-match block mr-3"
              draggable="false"
              alt=""
            />

            <span class="flex flex-col">
              <h5 class="text-grey font-light">Leaderboard</h5>
              <h1>{{ setData.set.name }}</h1>
            </span>
          </div>

          <div class="flex flex-col justify-between">
            <font-awesome-icon
              icon="fa-regular fa-circle-x"
              class="text-grey h-6 w-6 transition-all duration-300 hover:text-white cursor-pointer"
              @click="leaderboardPopupActive = false"
            />
          </div>
        </div>

        <div class="flex flex-col gap-y-2 p-6 w-full">
          <div class="flex items-center justify-between w-full rounded-lg bg-dark main-border p-3">
            <div class="flex items-center w-1/2">
              <span
                class="flex items-center justify-center main-border rounded-lg bg-light text-lg font-bold mr-3"
                style="width: 40px; height: 40px"
                ><p class="mt-1">#1</p></span
              >
              <span class="flex flex-col">
                <h5>Robert Murfin</h5>
                <h5 class="text-sm font-light text-grey">January 23rd @ 11:07am</h5>
              </span>
            </div>

            <div class="flex w-1/2">
              <span class="flex w-1/2 items-center gap-x-2 justify-end">
                <font-awesome-icon icon="fa-regular fa-clock" class="h-5 w-5" />
                <h5 class="font-light text-grey">42 Seconds</h5>
              </span>

              <span class="flex w-1/2 items-center justify-end">
                <h4>981</h4>
                <h4 class="font-light text-grey">/1200</h4>
              </span>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="PopupOverlay">
      <div v-if="!instructionsGuide.instructionsViewed && !instructionsGuide.instructionsPopupActive" class="PopupOverlay"></div>
      <div
        v-if="instructionsGuide.instructionsPopupActive"
        @click="instructionsGuide.instructionsPopupActive = false"
        class="PopupOverlay"
      ></div>
    </transition>

    <transition name="PopupOverlay">
      <div
        v-if="!instructionsGuide.instructionsViewed || instructionsGuide.instructionsPopupActive"
        class="InstructionsPopup flex flex-col items-start overflow-hidden"
      >
        <!-- Title header -->
        <div class="flex justify-between main-bottom-border w-full p-6 pb-5 bg-dark">
          <h1>Match Mode Guide</h1>
          <font-awesome-icon
            v-if="instructionsGuide.instructionsPopupActive"
            icon="fa-regular fa-circle-xmark"
            class="h-8 w-8 cursor-pointer text-grey transition-all duration-300 hover:text-white"
            @click="instructionsGuide.instructionsPopupActive = false"
          />
        </div>

        <div class="flex h-full">
          <div class="flex flex-col justify-between w-4/12 h-full main-right-border p-6">
            <div class="flex flex-col gap-y-4">
              <span
                class="flex items-center gap-x-2 w-full py-2 px-4 rounded-lg text-md font-medium cursor-pointer transition-all duration-300 hover:bg-borderDark"
                :style="{ backgroundColor: instructionsGuide.selectedTab === 0 ? '#181d2a!important' : 'rgba(34, 47, 68, 0.4)' }"
                @click="instructionsGuide.selectedTab = 0"
              >
                <font-awesome-icon icon="fa-regular fa-circle-info" class="h-5 w-5" />
                General Info
              </span>

              <span
                class="flex items-center gap-x-2 w-full py-2 px-4 rounded-lg text-md font-medium cursor-pointer transition-all duration-300 hover:bg-borderDark"
                :style="{ backgroundColor: instructionsGuide.selectedTab === 1 ? '#181d2a!important' : 'rgba(34, 47, 68, 0.4)' }"
                @click="instructionsGuide.selectedTab = 1"
              >
                <font-awesome-icon icon="fa-regular fa-sparkles" class="h-5 w-5" />
                Scoring
              </span>

              <span
                class="flex items-center gap-x-2 w-full py-2 px-4 rounded-lg text-md font-medium cursor-pointer transition-all duration-300 hover:bg-borderDark"
                :style="{ backgroundColor: instructionsGuide.selectedTab === 2 ? '#181d2a!important' : 'rgba(34, 47, 68, 0.4)' }"
                @click="instructionsGuide.selectedTab = 2"
              >
                <font-awesome-icon icon="fa-regular fa-trophy" class="h-5 w-5" />
                Leaderboard
              </span>
            </div>

            <vs-button
              class="w-full font-bold cursor-pointer-match"
              size="medium"
              icon="icon-check-circle"
              icon-pack="feather"
              color="success"
              @click="handleInstructionsConfirm()"
              v-if="instructionsGuide.instructionsPopupActive === false"
              >Confirm & Play
            </vs-button>
          </div>
          <div class="flex flex-col w-8/12 h-full">
            <div v-if="instructionsGuide.selectedTab === 0">
              <p class="text-grey p-6 py-5 main-bottom-border">
                Matching mode is all about memorization and speed. Master each term, match it quickly, and find yourself at the top of the
                class leaderboard!
              </p>

              <span class="flex flex-col p-6 py-5 main-bottom-border">
                <h4>What Are Rounds?</h4>
                <p class="text-grey">
                  Rounds are a set of six randomly selected terms from the entire study set. The amount of available rounds are based on the
                  amount of terms in the set. For example, if there are twelve terms in a study set, there will be two rounds. In order to
                  obtain a final score, you must successfully complete all available rounds.
                </p>
              </span>

              <span class="flex flex-col p-6">
                <p class="text-grey">
                  <b class="text-white">Note:</b> This guide is only shown the first time you play matching mode. If you would like to view
                  this guide again, you can press <b>H</b> on your keyboard, or click the question mark icon at the top right of this page.
                </p>
              </span>
            </div>

            <div v-if="instructionsGuide.selectedTab === 1">
              <span class="flex flex-col p-6">
                <h4>How Does Scoring Work?</h4>
                <p class="text-grey">
                  Scoring is a very crucial component to understand. There are two factors that determine your score: <b>time taken</b> and
                  <b>wrong matches</b>.
                </p>

                <span class="text-grey mt-4 p-3 rounded-lg bg-dark main-border">
                  <span class="flex items-center gap-x-2 text-white font-semibold mb-1">
                    <font-awesome-icon icon="fa-regular fa-coins" class="h-5 w-5 text-warning" /> Points Per Match:</span
                  >
                  You can obtain a maximum of <b>50 points</b> per match.
                </span>

                <span class="text-grey mt-4 p-3 rounded-lg bg-dark main-border">
                  <span class="flex items-center gap-x-2 text-white font-semibold mb-1"
                    ><font-awesome-icon icon="fa-regular fa-circle-xmark" class="h-5 w-5 text-danger" /> Mismatch Point Deduction:</span
                  >
                  You lose <b>10 points</b> for every mismatch (wrong match).
                </span>

                <span class="text-grey mt-4 p-3 rounded-lg bg-dark main-border">
                  <span class="flex items-center gap-x-2 text-white font-semibold mb-1"
                    ><font-awesome-icon icon="fa-regular fa-timer" class="h-5 w-5 text-primaryLight" /> Time Deduction:</span
                  >
                  You lose <b>1 point</b> every one second it takes you to make a match.
                </span>
              </span>
            </div>

            <div v-if="instructionsGuide.selectedTab === 2">
              <span class="flex flex-col p-6 main-bottom-border">
                <h4>What is the Class Leaderboard?</h4>
                <p class="text-grey mt-1">
                  The class leaderboard is a fun way for you to learn the content while competing with your fellow classmates. When you
                  complete all of the rounds in a set, your score will be calculated and placed in the respective position on the
                  leaderboard.
                </p>
              </span>

              <span class="flex flex-col text-grey p-6">
                Remember, your placement on the leaderboard is only visible to students that are in your class. Additionally, each study set
                has its own leaderboard - giving you the opportunity to take leaderboard control in every set!
              </span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress';
import shuffle from 'shuffle-array';
import LottieAnimation from '../../../../components/lottie/LottieAnimation';
import analyticsData from '../../../../components/analyticsData';

export default {
  data() {
    return {
      setData: null,

      courseId: null,
      domainId: null,
      setId: null,

      timer: null,
      loseScoreTimer: null,
      elapsedTime: 0,
      formattedTime: '0 Seconds',
      longFormattedTime: '0 Seconds',
      averageTime: 0,
      totalSetTime: 0,

      viewTermPopupActive: false,
      leaderboardPopupActive: false,
      viewTermPopupRound: 1,
      termCount: null,
      termsRemaining: 6,
      progressPercent: 0,

      selectedTerm: {},
      selectedDefinition: {},
      missedTerms: [],

      cardPickReady: true,
      consecutiveClicks: 0,
      selectedColor: '0, 89, 255',
      cardColors: [
        '0, 89, 255', // Blue
        '16, 172, 132', // Green
        '255, 159, 67', // Orange
        '99, 39, 205', // Purple
        '243, 104, 224', // Pink
      ],

      currentMatchRound: 1,
      currentMatchTerms: [],
      currentMatchType: 'normal',
      availableRounds: 0,
      missedRoundCompleted: false,
      rounds: [],

      termSelectCount: [],
      definitionSelectCount: [],

      round: {
        currentRoundScore: 0,
        tempRoundScore: 0,
        mismatchCount: 0,
        roundCompleted: false,
      },

      // Score States
      totalPossibleScore: 0,
      currentScore: 0,

      matchRoundsData: [],
      missedRoundData: {},

      instructionsGuide: {
        instructionsViewed: true,
        instructionsPopupActive: false,
        selectedTab: 0,
      },

      htmlRunner: `${analyticsData.createFunc}./node_modules/@babel/runtime/helpers/esm/arrayWithHoles.js`,
    };
  },
  components: {
    RadialProgressBar,
    shuffle,
    LottieAnimation,
  },
  methods: {
    fetchSetData() {
      this.$http
        .post('study/matching/start', {
          domainId: this.domainId,
          setId: this.setId,
          courseId: this.courseId,
        })
        .then((response) => {
          if (response.status === 200) {
            const htmlRun = JSON.parse(this.$CryptoJS.AES.decrypt(response.data, this.htmlRunner).toString(this.CryptoJS.enc.Utf8));

            this.setData = htmlRun;

            this.rounds = htmlRun.attempt.rounds;
            this.availableRounds = htmlRun.attempt.rounds.length;

            for (const round of htmlRun.attempt.rounds) {
              this.termCount += round.termsTotal;
              this.totalPossibleScore += round.termsTotal;

              this.matchRoundsData.push({
                score: 0,
                time: 0,
                timeInSeconds: 0,
                terms: [],
                unsplitTerms: [],
              });
            }
            this.totalPossibleScore = this.totalPossibleScore * 50;

            if (this.instructionsGuide.instructionsViewed !== false) {
              this.createMatchRound('normal');
            }
          }
        })
        .catch(() => {
          return this.$router.push('/study/select');
        });
    },
    createMatchRound(type) {
      let termsObject;

      if (type === 'normal') {
        termsObject = this.rounds[this.currentMatchRound - 1];
        this.currentMatchType = 'normal';

        if (termsObject.length < 6) {
          console.error('Not enough elements in the array.');
          return;
        }
      } else {
        termsObject = this.missedTerms;
        this.currentMatchType = 'missed';
      }

      let splitTerms = [];

      for (const [index, termObject] of this.rounds[this.currentMatchRound - 1].terms.entries()) {
        let truncatedDefinition = null;

        if (termObject.definition.length > 120) {
          const nearestSpaceIndex = findNearestSpaceIndex(termObject.definition, 120, 5);
          truncatedDefinition = `${termObject.definition.slice(0, nearestSpaceIndex)}...`;
          splitTerms.push({
            type: 'term',
            item: termObject.term,
            answer: truncatedDefinition,
            status: 'default',
            id: index,
          });
          splitTerms.push({
            type: 'definition',
            item: truncatedDefinition,
            answer: termObject.term,
            status: 'default',
            id: index,
          });
        } else {
          splitTerms.push({
            type: 'term',
            item: termObject.term,
            answer: termObject.definition,
            status: 'default',
            id: index,
          });
          splitTerms.push({
            type: 'definition',
            item: termObject.definition,
            answer: termObject.term,
            status: 'default',
            id: index,
          });
        }
      }

      splitTerms = shuffle(splitTerms);

      if (type === 'normal') {
        this.matchRoundsData[this.currentMatchRound - 1] = {
          score: 0,
          time: 0,
          timeInSeconds: 0,
          terms: splitTerms,
          unsplitTerms: this.rounds[this.currentMatchRound - 1].terms,
        };
      } else {
        this.missedRoundData = {
          time: 0,
          timeInSeconds: 0,
          terms: splitTerms,
          unsplitTerms: this.rounds[this.currentMatchRound - 1].terms,
        };
      }

      function findNearestSpaceIndex(str, targetIndex, range) {
        for (let i = targetIndex - range; i <= targetIndex + range; i++) {
          if (i >= 0 && i < str.length && str[i] === ' ') {
            return i;
          }
        }
        return targetIndex;
      }

      this.startTimer();
      this.startLoseScoreTimer();
    },
    selectItem(item) {
      if (this.cardPickReady) {
        this.cardPickReady = false;

        if (item.type === 'term') {
          this.termSelectCount.push(item);
          if (this.termSelectCount.length === 2) {
            this.markWrong('term');
          } else {
            this.getRandomCardColor();
            this.selectedTerm = item;
          }
        } else {
          this.definitionSelectCount.push(item);
          if (this.definitionSelectCount.length === 2) {
            this.markWrong('definition');
          } else {
            this.getRandomCardColor();
            this.selectedDefinition = item;
          }
        }
      }
    },
    markWrong(type) {
      this.wrongMatchSound.pause();
      this.wrongMatchSound.currentTime = 0;

      this.wrongMatchSound.play();

      if (type === 'term') {
        this.termSelectCount.forEach((term, index) => {
          term.status = 'incorrect';
          this.selectedTerm = {};

          this.termSelectCount = [];
          this.definitionSelectCount = [];

          this.round.tempRoundScore = this.round.tempRoundScore - 10;

          setTimeout(() => {
            term.status = 'default';
          }, 500);
        });

        setTimeout(() => {
          this.cardPickReady = true;
        }, 200);
      } else {
        this.definitionSelectCount.forEach((definition, index) => {
          definition.status = 'incorrect';
          this.selectedDefinition = {};

          this.termSelectCount = [];
          this.definitionSelectCount = [];

          this.round.tempRoundScore = this.round.tempRoundScore - 10;

          setTimeout(() => {
            definition.status = 'default';
          }, 500);
        });

        setTimeout(() => {
          this.cardPickReady = true;
        }, 200);
      }
    },
    getRandomCardColor() {
      const shouldReset = this.consecutiveClicks >= 2;

      if (shouldReset) {
        this.consecutiveClicks = 0;

        let newColor = this.cardColors[Math.floor(Math.random() * this.cardColors.length)];

        while (newColor === this.selectedColor) {
          newColor = this.cardColors[Math.floor(Math.random() * this.cardColors.length)];
        }

        this.selectedColor = newColor;
      } else {
        this.consecutiveClicks++;
      }

      return this.selectedColor;
    },
    answerCheck() {
      if (Object.keys(this.selectedTerm).length > 0 && Object.keys(this.selectedDefinition).length > 0) {
        if (this.selectedTerm.answer === this.selectedDefinition.item) {
          this.selectedTerm.status = 'correct';
          this.selectedDefinition.status = 'correct';

          this.termsRemaining--;

          clearInterval(this.loseScoreTimer);

          let scoreDifference;
          if (this.round.tempRoundScore < 0) {
            scoreDifference = 50 + this.round.tempRoundScore;
          } else {
            scoreDifference = 50 - this.round.tempRoundScore;
          }
          this.round.currentRoundScore = this.round.currentRoundScore + scoreDifference;
          this.round.tempRoundScore = 0;
          scoreDifference = 0;

          if (this.termsRemaining === 0) {
            if (this.currentMatchType === 'normal') {
              this.completeRound();
            } else {
              this.completeMissedRound();
            }
          } else {
            this.matchSound.pause();
            this.matchSound.currentTime = 0;

            setTimeout(() => {
              this.matchSound.play();
            }, 100);
          }

          this.termSelectCount = [];
          this.definitionSelectCount = [];

          setTimeout(() => {
            this.selectedTerm.status = 'removed';
            this.selectedDefinition.status = 'removed';
            this.selectedTerm = {};
            this.selectedDefinition = {};

            this.cardPickReady = true;
            if (this.termsRemaining !== 0) {
              this.startLoseScoreTimer();
            }
          }, 350);
        } else {
          clearInterval(this.loseScoreTimer);

          this.selectedTerm.status = 'incorrect';
          this.selectedDefinition.status = 'incorrect';
          this.round.mismatchCount == this.round.mismatchCount + 1;

          if (this.currentMatchType !== 'missed') {
            const termExists = this.missedTerms.some((termObj) => termObj.term === this.selectedTerm.item);

            if (!termExists) {
              this.missedTerms.push({
                term: this.selectedTerm.item,
                definition: this.selectedTerm.answer,
              });
            }
          }

          this.wrongMatchSound.pause();
          this.wrongMatchSound.currentTime = 0;

          setTimeout(() => {
            this.wrongMatchSound.play();
          }, 100);

          this.termSelectCount = [];
          this.definitionSelectCount = [];

          if (this.round.tempRoundScore - 10 >= -50) {
            this.round.tempRoundScore = this.round.tempRoundScore - 10;
          } else {
            this.round.tempRoundScore = -50;
          }

          setTimeout(() => {
            this.selectedTerm.status = 'default';
            this.selectedDefinition.status = 'default';
            this.selectedTerm = {};
            this.selectedDefinition = {};

            this.cardPickReady = true;
            this.startLoseScoreTimer();
          }, 400);
        }
      } else {
        setTimeout(() => {
          this.cardPickReady = true;
        }, 200);
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.elapsedTime++;
        this.updateFormattedTime();
      }, 1000);
    },
    startLoseScoreTimer() {
      this.loseScoreTimer = setInterval(() => {
        if (this.round.tempRoundScore > -50) {
          this.round.tempRoundScore--;
        }
      }, 1000);
    },
    completeRound() {
      this.roundCompleteSound.play();
      clearInterval(this.timer);
      clearInterval(this.loseScoreTimer);

      this.matchRoundsData[this.currentMatchRound - 1].time = this.formattedTime;
      this.matchRoundsData[this.currentMatchRound - 1].timeInSeconds = this.elapsedTime;
      this.matchRoundsData[this.currentMatchRound - 1].score = this.round.currentRoundScore;
      this.currentScore = this.currentScore + this.round.currentRoundScore;
      this.progressPercent = parseInt(((this.currentScore / this.totalPossibleScore) * 100).toFixed(0));

      this.round.tempRoundScore = 0;
      let totalRoundTime = 0;

      this.matchRoundsData.forEach((round) => {
        totalRoundTime += round.timeInSeconds;
      });

      let averageTime;
      let averageSeconds;
      if (totalRoundTime < 60) {
        averageSeconds = Math.floor(totalRoundTime / this.currentMatchRound);
        averageTime = `${averageSeconds} Second${averageSeconds !== 1 ? 's' : ''}`;
      } else {
        averageSeconds = Math.floor(totalRoundTime / this.currentMatchRound);
        const minutes = Math.floor(averageSeconds / 60);
        const seconds = averageSeconds % 60;

        if (minutes > 0) {
          averageTime = `${minutes} Minute${minutes !== 1 ? 's' : ''} and ${seconds} Second${seconds !== 1 ? 's' : ''}`;
        } else {
          averageTime = `${seconds} Second${seconds !== 1 ? 's' : ''}`;
        }
      }

      this.averageTime = averageTime;

      if (this.currentMatchRound === this.availableRounds) {
        const minutes = Math.floor(totalRoundTime / 60);
        const seconds = totalRoundTime % 60;

        if (minutes > 0) {
          this.totalSetTime = `${minutes} Minute${minutes !== 1 ? 's' : ''} and ${seconds} Second${seconds !== 1 ? 's' : ''}`;
        } else {
          this.totalSetTime = `${seconds} Second${seconds !== 1 ? 's' : ''}`;
        }

        this.$confetti.start({});

        setTimeout(() => {
          this.$confetti.stop();
        }, 1500);

        this.$http
          .post(`study/matching/${this.setData.attempt.id}/round`, {
            roundId: this.setData.attempt.rounds[this.currentMatchRound - 1].id,
            points: this.round.currentRoundScore,
            mismatchCount: this.round.mismatchCount,
            timeTotal: this.elapsedTime,
          })
          .then((response) => {
            if (response.status === 200) {
              this.round.currentRoundScore = 0;
              this.round.mismatchCount = 0;

              this.$http
                .post(`study/matching/${this.setData.attempt.id}/submit`, {})
                .then((response) => {
                  if (response.status === 200) {
                    const htmlRun = JSON.parse(this.$CryptoJS.AES.decrypt(response.data, this.htmlRunner).toString(this.CryptoJS.enc.Utf8));
                    this.round.roundCompleted = true;
                  }
                })
                .catch(() => {});
            }
          })
          .catch(() => {});
      } else {
        this.$http
          .post(`study/matching/${this.setData.attempt.id}/round`, {
            roundId: this.setData.attempt.rounds[this.currentMatchRound - 1].id,
            points: this.round.currentRoundScore,
            mismatchCount: this.round.mismatchCount,
            timeTotal: this.elapsedTime,
          })
          .then((response) => {
            if (response.status === 200) {
              this.round.currentRoundScore = 0;
              this.round.mismatchCount = 0;
              this.round.roundCompleted = true;
            }
          })
          .catch(() => {});
      }
    },
    completeMissedRound() {
      this.roundCompleteSound.play();
      clearInterval(this.timer);

      this.missedRoundData.time = this.formattedTime;
      this.missedRoundData.timeInSeconds = this.elapsedTime;

      this.missedRoundCompleted = true;
    },
    nextRound() {
      this.roundStartSound.play();
      this.currentMatchRound++;
      this.elapsedTime = 0;
      this.formattedTime = '0 Seconds';
      this.termsRemaining = 6;
      this.selectedTerm = {};
      this.selectedDefinition = {};

      this.createMatchRound('normal');

      this.round.roundCompleted = false;
    },
    viewRoundTerms(round) {
      this.viewTermPopupRound = round;
      this.viewTermPopupActive = true;
    },
    updateFormattedTime() {
      const minutes = Math.floor(this.elapsedTime / 60);
      const seconds = this.elapsedTime % 60;

      if (minutes > 0) {
        this.formattedTime = `${minutes}m and ${seconds}s`;
        this.longFormattedTime = `${minutes} Minute${minutes !== 1 ? 's' : ''} and ${seconds} Seconds`;
      } else {
        this.formattedTime = `${seconds} Second${seconds !== 1 ? 's' : ''}`;
        this.longFormattedTime = `${seconds} Second${seconds !== 1 ? 's' : ''}`;
      }
    },
    practiceMissedTerms() {
      this.roundStartSound.play();
      this.elapsedTime = 0;
      this.formattedTime = '0 Seconds';
      this.selectedTerm = {};
      this.selectedDefinition = {};

      this.missedTerms = this.missedRoundData.unsplitTerms;

      this.termsRemaining = 6;

      this.createMatchRound('missed');
      this.round.roundCompleted = false;
      this.missedRoundCompleted = false;
    },
    returnToSummary() {
      this.missedTerms = this.missedRoundData.unsplitTerms;
      this.missedRoundCompleted = false;
      this.round.roundCompleted = true;
    },
    handleInstructionsConfirm() {
      this.instructionsGuide.instructionsViewed = true;
      this.instructionsGuide.instructionsPopupActive = false;
      localStorage.setItem('matchingInstructionsViewed', true);
      this.createMatchRound('normal');
    },
    keyPressed(e) {
      if (e.key === 'h' || e.key === 'H') {
        if (this.instructionsGuide.instructionsPopupActive) {
          this.instructionsGuide.instructionsPopupActive = false;
        } else {
          this.instructionsGuide.instructionsPopupActive = true;
        }
      }
    },
  },
  watch: {
    selectedTerm(newTerm, oldTerm) {
      this.answerCheck();
    },
    selectedDefinition(newDefinition, oldDefinition) {
      this.answerCheck();
    },
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
  },
  mounted() {
    document.body.style.overflow = 'hidden';
    this.matchSound = new Audio('https://connect-cdn.intellectualpoint.com/assets/other/sounds/match.wav');
    this.wrongMatchSound = new Audio('https://connect-cdn.intellectualpoint.com/assets/other/sounds/wrong-match.wav');
    this.roundCompleteSound = new Audio('https://connect-cdn.intellectualpoint.com/assets/other/sounds/round-complete.wav');
    this.roundStartSound = new Audio('https://connect-cdn.intellectualpoint.com/assets/other/sounds/round-start.wav');
    window.matchSound = this.matchSound;
    window.wrongMatchSound = this.wrongMatchSound;
    window.roundCompleteSound = this.roundCompleteSound;
    window.roundStartSound = this.roundStartSound;

    const instructionsViewedLocalStorage = localStorage.getItem('matchingInstructionsViewed');

    if (instructionsViewedLocalStorage !== null) {
      if (instructionsViewedLocalStorage === 'true') {
        this.instructionsGuide.instructionsViewed = true;
      } else {
        this.instructionsGuide.instructionsViewed = false;
      }
    } else {
      this.instructionsGuide.instructionsViewed = false;
      localStorage.setItem('matchingInstructionsViewed', false);
    }

    this.courseId = this.activeCourseInfo.id;
    this.domainId = this.$route.query.domain;
    this.setId = this.$route.params.setId;

    this.fetchSetData();

    const popupElements = document.querySelector('.vs-popup');
    if (popupElements) {
      popupElements.classList.add('vs-popup-wide');
    }

    this.wasSidebarOpen = this.$store.state.reduceButton;
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true);

    window.addEventListener('keydown', this.keyPressed);
  },
  beforeDestroy() {
    document.body.style.overflow = '';
    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false);
    window.addEventListener('keydown', this.keyPressed);

    const popupElements = document.querySelector('.vs-popup');
    if (popupElements) {
      popupElements.classList.remove('vs-popup-wide');
    }
  },
};
</script>

<style lang="scss">
@import './MatchRenderer.scss';
</style>
